import { QueryClient } from '@tanstack/vue-query'
import { useOptimisticMutation } from './base'
import {
   ProjectDto,
   UpdateProjectRequest
} from '@/api/contracts/models'
import QueryKeys from '@/vue-query/QueryKeys'
import { useProjectApi } from '@/composables/api/useProjectApi'

export function useProjectUpdate(queryClient: QueryClient) {
   const client = useProjectApi()
   const apiFn = (data: UpdateProjectRequest) => {
      return client.update(data.projectId!, data)
   }

   const optimisticFn = (old: ProjectDto[], data: UpdateProjectRequest) => {
      const values = old ? [...old] : []

      const project = values.find((m) => m.id == data.projectId)

      if (project) {
         const projectIdx = values.indexOf(project)

         values[projectIdx] = {
            ...project,
            name: data.displayName
         }
      }

      return values
   }

   return useOptimisticMutation(
      [QueryKeys.Projects],
      queryClient,
      apiFn,
      optimisticFn
   )
}
